import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeleteUserDataMutation,
  useGetUsersListDataQuery,
  useStatusChangeMutation,
  useUpdateUsersListDataMutation,
} from "../../../store/queries/users";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Users/usersSlice";
import { toast } from "react-toastify";
import { addDays } from "date-fns";
import { current } from "@reduxjs/toolkit";
import { users as usersQuery } from "../../../store/queries/users";
import currencyData from "../../../utils/components/currencyCode";

const useUsersList = () => {
  const dispatch = useDispatch();
  const usersState = useSelector((state) => state.users);
  const { showCreateModal, selectedUpdateRows } = useSelector(
    (state) => state.users
  );
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [userDelete] = useDeleteUserDataMutation();
  const [updateUserFields] = useUpdateUsersListDataMutation();
  const [statusChange] = useStatusChangeMutation();

  const [filterShow, setFilterShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: users = {},
    isFetching,
    isLoading,
    isSuccess,
    refetch,
  } = useGetUsersListDataQuery({
    length: usersState.currentPageSize,
    page: usersState.currentPage,
    filter: usersState.currentFilter,
    search: usersState.search,
    sort_by: usersState.sortBy,
    sort_order: usersState.sortOrder,
    // start: usersState.startDate,
    // end: usersState.endDate,
    from_date: usersState.from_date,
    to_date: usersState.to_date,
    status: usersState.status,
  });

  const hasCreatePermission = useMemo(() => {
    let permission = users?.data?.original?.permissions?.create_permission;
    return permission ?? false;
  }, [users]);

  const hasEditPermission = useMemo(() => {
    let permission = users?.data?.original?.permissions?.edit_permission;
    return permission ?? false;
  }, [users]);
  const hasViewPermission = useMemo(() => {
    let permission = users?.data?.original?.permissions?.list_permission;
    return permission ?? false;
  }, [users]);
  const hasDeletePermission = useMemo(() => {
    let permission = users?.data?.original?.permissions?.delete_permission;
    return permission ?? false;
  }, [users]);

  const tableHeading = {
    name: { label: "Name", sortable: true },
    country: { label: "Country", sortable: true },
    mobile: { label: "Mobile Number", sortable: true },
    email: { label: "Email", sortable: true },
    dob: { label: "Date of Birth", sortable: true },
    create_date: { label: "Created At", sortable: true },
    status_label: { label: "Status", sortable: true },
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableHeading;
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refetch();
  }, [usersState?.status]);

  useEffect(() => {
    if (usersState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [usersState.clearSelection]);

  const deleteUserDataClick = (item) => {
    setShowDeleteModal(true);
    dispatch(
      updateConfig((state) => {
        state.selectedUpdateRows = item;
      })
    );
  };

  const deleteUser = () => {
    userDelete({
      _id: selectedUpdateRows,
    }).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
        setShowDeleteModal(false);
        toast.success("Successfully Deleted");
      } else if (response?.error?.status === 422) {
        toast.success("Something went wrong");
      }
    });
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (usersState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = usersState.sortOrder === "0" ? "1" : "0";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "0";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleUserDataEditClick = (id) => {
    dispatch(
      updateConfig((state) => {
        state.activeId = id?.[0];
        state.showCreateModal = true;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.currentFormData = users?.data?.original?.data?.id;
      })
    );
  };

  const closeModal = () => {
    refetch();
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentFilter = null;
        state.currentPageSize = 10;
        state.startDate = null;
        state.endDate = null;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const handleToggle = (e, data) => {
    let params = {
      _id: data?._id,
    };
    statusChange(params);
    dispatch(
      usersQuery.util.updateQueryData(
        "getUsersListData",
        {
          length: usersState.currentPageSize,
          page: usersState.currentPage,
          filter: usersState.currentFilter,
          search: usersState.search,
          sort_by: usersState.sortBy,
          sort_order: usersState.sortOrder,
          // start: usersState.startDate,
          // end: usersState.endDate,
          from_date: usersState.from_date,
          to_date: usersState.to_date,
          status: usersState.status,
        },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.original?.data?.map((res) =>
            res?._id === data?._id
              ? {
                  ...res,
                  status: data?.status === 0 ? 1 : 0,
                }
              : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              original: {
                ...updatedCachedData.data.original,
                data: newResult,
              },
            },
          };
          return updatedCachedData;
        }
      )
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => (
        <p className="pro-mb-0">{data[feild] ? data[feild] : "-"}</p>
      ),
      country: (feild, data) => {
        return (
          <>
            <img src={getCoutryIconFromName(data?.country?.name)} alt="" />
            <span className="pro-ps-1">{data?.country?.name}</span>
          </>
        );
      },
      status_label: (field, data) => {
        const isChecked = data?.status === 1;
        return (
          <>
            {hasEditPermission ? (
              <div className="form-check form-switch ">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={isChecked}
                  onChange={(e) => handleToggle(e, data)}
                />
              </div>
            ) : (
              data?.status_label?.name
            )}
          </>
        );
      },
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const getCoutryIconFromName = (name = "") => {
    const currencyObj = currencyData.find(
      (obj) => obj.country.toLowerCase() === name?.toLowerCase?.()
    );
    if (currencyObj) {
      return currencyObj.flag;
    } else {
      return false;
    }
  };

  const headerLabel = {
    total_user: "Total Users",
  };
  const HeaderValue = {
    total_user: `${
      users?.data?.original?.recordsTotal ?? "0"
    }`,
    
  };

  const headerDetails = Object.keys(HeaderValue).map((key) => {
    return {
      label: headerLabel[key],
      value:
        typeof HeaderValue[key] === "string"
          ? HeaderValue?.[key]
          : typeof HeaderValue[key] === "number"
          ? HeaderValue?.[key]
          : HeaderValue?.[key]?.name,
    };
  });


  return {
    users,
    usersState,
    currentPage: usersState.currentPage,
    activeFilter: usersState.currentFilter,
    showCreateModal,
    showEditModal,
    paginationOptions,
    isFetching,
    isLoading,
    isSuccess,
    totalRecords: usersState.recordsTotal,
    showDeleteModal,
    date,
    filterShow,
    setFilterShow,
    refetch,
    updateUserFields,
    deleteUserDataClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    handleSort,
    handleSearch,
    handleUserDataEditClick,
    handleClearClick,
    closeEditModal,
    closeModal,
    getRow,
    deleteUser,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
    headerDetails
  };
};

export default useUsersList;
