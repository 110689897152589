import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
// import { da } from "date-fns/locale";
// import Orders from "../../Orders/orderListing";
import { useGetHomeDashboardListDataQuery } from "../../../store/queries/homeDashboard";
import { updateConfig } from "../../../store/slices/HomeDashboard/homeDashboardSlice";
import { updateConfig as genresUpdateConfig } from "../../../store/slices/TopGenres/topGenresSlice";
import { updateConfig as programUpdateConfig } from "../../../store/slices/TopPrograms/topProgramsSlice";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import Orders from "../../Orders";
// import { updateConfig } from "../../../store/slices/Orders/ordersSlice";
// import Transactions from "../../Transactions";
import Assets from "../../../assets/Assets";
import { getFormatedDate, getFormatedDateRange } from "../../../utils/functions/table";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { exportAllData } from "../../../store/slices/HomeDashboard/homeDashboardSlice";
import { toast } from "react-toastify";
import moment from "moment";

ChartJS.register(ArcElement, Tooltip, Legend);


const useHomeDashboardListing = () => {
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get("activeTab");
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.homeDashboard);
  const topProgramState = useSelector((state) => state.topPrograms);
  const topGenresState = useSelector((state) => state.topGenres);
  const {
    data: dashboardData = {},
    isFetching,
    isSuccess,
  } = useGetHomeDashboardListDataQuery({
    // date_period: "this_week",
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    from_date: getFormatedDate(menuState?.startDate),
    to_date: getFormatedDate(menuState?.endDate),
    dashboard_type : activeTab === "app_analytics" ? 1 : 2,
  });

  useEffect(() => {
    dispatch(updateConfig((state) => {
      state.activeTab = activeTab
      state.dashboard_type = activeTab === "app_analytics" ? 1 : 2
    }));
    dispatch(programUpdateConfig((state) => {
      state.startDate = menuState?.startDate
      state.endDate = menuState?.endDate
    }));
    dispatch(genresUpdateConfig((state) => {
      state.startDate = menuState?.startDate
      state.endDate = menuState?.endDate
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

const totals = dashboardData?.data?.totals;

  const keysToKeep = ["total_users", "active_users", "total_plays"];

  const filteredTotals =
    totals &&
    Object.keys(totals)
      .filter((key) => keysToKeep.includes(key))
      .reduce((obj, key) => {
        obj[key] = totals[key];
        return obj;
      }, {});

      let dashboardItems = {
        total_users: {
          label: "total_users",
          title: "New Registered Users",
          icon: Assets.USERS_GROUP,
        },
        active_users: {
          label: "active_users",
          title: "Active Users",
          icon: Assets.ACTIVE_USER,
        },
        total_plays: {
          label: "total_plays",
          title: "Total Plays",
          icon: Assets.VIDEOS,
        },
        // podcasts: {
        //   label: "podcasts",
        //   title: "Podcasts",
        //   icon: Assets.PODCASTS
        // },
        // shorts: {
        //   label: "shorts",
        //   title: "Shorts",
        //   icon: Assets.SHORTS,
        // },
      };

      


  
  const barData = {
      labels: dashboardData?.data?.topCountries?.labels,
      datasets:[
        {
          label: "",
          data: dashboardData?.data?.topCountries?.data,
          backgroundColor: [
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)',
            'rgba(245, 158, 11, 1)'
          ],
          borderWidth: 0,
        barThickness: 22,
        }
      ]
        
    };

    const lineData = useMemo(() => {
      return {
        labels: dashboardData?.data?.streamsLineChart?.labels,
        datasets:
          dashboardData?.data?.streamsLineChart?.data?.map((item, index) => ({
            label: item.labels,
            data: item.data,
            backgroundColor: [
              "rgba(245, 158, 11, 0.2)",
              "rgba(217, 119, 6, 0.2)",
              "rgba(253, 186, 116, 0.2)",
            ],
            fill: true,
            borderColor: [
              "rgba(245, 158, 11, 1)",
              "rgba(217, 119, 6, 1)",
              "rgba(253, 186, 116, 1)",
            ],
            pointRadius: 10,
            pointHoverRadius: 15,
            pointBackgroundColor: "transparent",
            pointBorderColor: "transparent",
          })) || [],
      };
    }, [dashboardData?.data?.streamsLineChart]);

    const lineOptions = {
      plugins: {
        legend: {
          position: "bottom",
          align: "center",
          labels: {
            boxWidth: 8,
            boxHeight: 8,
            borderColor: "transparent",
            itemMargin: 5,
            font: {
              size: 12,
            },
          },
        },
        tooltip: {
          backgroundColor: 'white',
          titleColor: 'black',
          bodyColor: 'black',
          borderColor: 'rgba(0, 0, 0, 0.1)',
          borderWidth: 1
        },
        layout: {
          padding: {
            left: 0,
            right: 10,
            top: 0,
            bottom: 50,
          },
        },
      },
      scales: {
        x: {
          display: true,
        title: {
          display: true
        },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        y: {
          ticks: {
            beginAtZero: true,
          },
          grid: {
            color: "#D2D1D4",
          },
          border: {
            display: false,
            dash: [4, 4],
          },
          display: true,
          title: {
            display: true,
            text: 'Views',
            
          }
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      width: 300,
      height: 300,
      aspectRatio: 1,
    };

    const ageBarData = {
      labels: dashboardData?.data?.ageCategoryBarChart?.labels,
      datasets: dashboardData?.data?.package_sales?.datasets
       
    };

    const ageBarDataOptions = {
      indexAxis: "x",
      plugins: {
        legend: {
          position: "bottom",
          align: "center",
          labels: {
            boxWidth: 8,
            boxHeight: 8,
            borderColor: "transparent",
            itemMargin: 5,
            font: {
              size: 12,
            },
          },
        },
        tooltip: {
          backgroundColor: 'white',
          titleColor: 'black',
          bodyColor: 'black',
          borderColor: 'rgba(0, 0, 0, 0.1)',
          borderWidth: 1
        },
        layout: {
          padding: {
            left: 0,
            right: 10,
            top: 0,
            bottom: 50,
          },
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        y: {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
          grid: {
            color: "#D2D1D4",
          },
          border: {
            display: false,
            dash: [4, 4],
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      width: 300,
      height: 300,
      aspectRatio: 1,
    };

    const barDataOptions = {
      indexAxis: "x",
      plugins: {
        legend: {
          display: false,
          position: "top",
        },
        tooltip: {
          backgroundColor: 'white',
          titleColor: 'black',
          bodyColor: 'black',
          borderColor: 'rgba(0, 0, 0, 0.1)',
          borderWidth: 1
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        y: {
          stacked: true,
          ticks: {
            beginAtZero: true,
          },
          grid: {
            color: "#D2D1D4",
          },
          border: {
            display: false,
            dash: [4, 4],
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      width: 300,
      height: 300,
      aspectRatio: 1,
    };
  

  const doughnutData = {
    labels: dashboardData?.data?.genderPieChart?.labels,
    datasets: [
      {
        data: dashboardData?.data?.genderPieChart?.data,
        backgroundColor: [
          "rgba(245, 158, 11, 1)",
          "rgba(217, 119, 6, 1)",
          "rgba(253, 186, 116, 1)",
        ],
      },
    ],
  };
  
  
  const doughnutTextCenter = {
    id: 'doughnutTextCenter',
    afterDatasetsDraw(chart,args,plugins){
      const { ctx,doughnutData } = chart; 
      const centerX = chart.getDatasetMeta(0)?.data[0]?.x;
      const centerY = chart.getDatasetMeta(0)?.data[0]?.y;
      ctx.save();
      ctx.font = `bold 35px sans-serif`;
      ctx.fillStyle ='black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle'
      ctx.fillText(dashboardData?.data?.genderPieChart?.total_count ? `${dashboardData?.data?.genderPieChart?.total_count}` : `0`, centerX, centerY);
    },
  };

  const semiDoughnutTextCenter = {
    id: 'semiDoughnutTextCenter',
    afterDatasetsDraw(chart,args,plugins){
      const { ctx,doughnutData } = chart; 
      const centerX = chart.getDatasetMeta(0)?.data[0]?.x;
      const centerY = chart.getDatasetMeta(0)?.data[0]?.y;
      ctx.save();
      ctx.font = `bold 35px sans-serif`;
      ctx.fillStyle ='black';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle'
      ctx.fillText(dashboardData?.data?.devicesPieChart?.total_count ? `${dashboardData?.data?.devicesPieChart?.total_count}` : `0`, centerX, centerY - 20);
    },
  };

  
  const deviceDoughnutData = {
    labels: dashboardData?.data?.devicesPieChart?.labels,
    //datasets: dashboardData?.data?.devicesPieChart?.datasets
    datasets: [
      {
        data: dashboardData?.data?.devicesPieChart?.data,
        backgroundColor: [
          "rgba(245, 158, 11, 1)",
           "rgba(217, 119, 6, 1)",
          "rgba(253, 186, 116, 1)",
        ],
      },
    ],
  };

  const deviceDoughnutOption = {
   plugins: {
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          borderColor: "transparent",
          itemMargin: 5,
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        backgroundColor: 'white',
        titleColor: 'black',
        bodyColor: 'black',
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1
      },
      layout: {
        padding: {
          left: 0,
          right: 10,
          top: 0,
          bottom: 50,
        },
      },
    },
   
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    circumference : 180,
    rotation: -90,
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    cutout:'65%',
    aspectRatio: 1,
  };

  const genderGraph = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        align: "end",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          font: {
            size: 10,
          },
        },
      },
      tooltip: {
        backgroundColor: 'white',
        titleColor: 'black',
        bodyColor: 'black',
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1
      },
      afterDraw: () => {},
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    cutout: '65%',
    aspectRatio: 1,
  };

  const navigation = [
    {
      label: "App Analytics",
      title: "App Analytics",
      link: `/home-dashboard?activeTab=app_analytics`,
      active: activeTab === "app_analytics",
    },
    {
      label: "Content Analytics",
      title: "Content Analytics",
      link: `/home-dashboard?activeTab=content_analytics`,
      active: activeTab === "content_analytics",
    }
  ];

  const [date, setDate] = useState({
    startDate: menuState.startDate,
    endDate: menuState.endDate,
    key: "selection",
  });

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];


    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };


  const ExportData = () => {
    try {
      let obj = {
    from_date: getFormatedDate(menuState?.startDate),
    to_date: getFormatedDate(menuState?.endDate),
    dashboard_type : activeTab === "app_analytics" ? 1 : 2  
      };
      if(activeTab != "app_analytics") {
        obj.program_sort_by = topProgramState.sortBy;
        obj.program_sort_order = topProgramState.sortOrder;
        obj.category_sort_by = topGenresState.sortBy;
        obj.category_sort_order = topGenresState.sortOrder;
        obj.program_media_type = topProgramState?.media_type;
        obj.category_media_type = topGenresState?.media_type;
      }

      exportAllData(obj).then((response) => {
  
        if (response?.status === 200) {
          
          toast.success("Success");

          let downloadLink = document.createElement("a");
          let blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.setAttribute(
            "download",
            `${activeTab}_${getFormatedDateRange(date?.startDate)} - ${getFormatedDateRange(date?.endDate)}.xlsx`
          );

          document.body.appendChild(downloadLink);
          downloadLink.click();
          
        } else if (response?.data?.error_code === 403) {
          toast.error(response?.data?.errors);
        } else if (response?.status_code === 500) {
          toast.error("Something Went Wrong");
        } 
      });
    } catch (error) {
      toast.error("Something went wrong");
    }
  }


  return {
    tabNavs: navigation,
    dashboardItems,
    dashboardData,
    isFetching,
    // children: dashboardItems[listingType]?.component,
    // listingType,
    doughnutData,
    genderGraph,
    doughnutTextCenter,
    semiDoughnutTextCenter,
    barData,
    barDataOptions,
    filteredTotals,
    ageBarData,
    ageBarDataOptions,
    lineData,
    lineOptions,
    deviceDoughnutData,
    deviceDoughnutOption,
    handleDateRangeChange,
    date,
    activeTab,
    ExportData
  };
};

export default useHomeDashboardListing;
